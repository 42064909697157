body.results-view {
  .wrapper {
    padding-bottom:calc( #{$navbar-height} + #{$navbar-margin-bottom} + 39px);
  }
  .navbar-fixed-bottom {
    bottom:38px;
  }
  footer {
    position:fixed;
    bottom:0;
    width:100%;
  }
  &.analyze-results {
    .wrapper .main {
      padding-top:0;

      .results [ui-view] {
        padding-top:calc( #{$navbar-height} + #{$navbar-margin-bottom} + 2px);
      }
    }
  }
}

.results {
  &.ng-enter {
    animation: fadeInUp .35s;
  }
}

.navbar-fixed-bottom {
  &.ng-enter {
    animation: slideInUp .5s;
  }
}

.results-sample-header {
  display:flex;
  text-align:center;
  position:sticky;
  top:0;
  padding-bottom:15px;
  margin-bottom:16px;
  background:$body-bg;
  z-index:$zindex-navbar + 1;

  .header-item {
    align-items:flex-end;
    flex-grow:3;
    &.pager-btn {
      &:first-child {
        flex-grow:1;
        display:flex;
        justify-content: flex-start;
      }
      &:last-child {
        flex-grow:1;
        display:flex;
        justify-content: flex-end;
        flex-wrap:nowrap;
      }
    }
    &.title {
      white-space:nowrap;
      max-width:50vw;
      display:flex;
      justify-content:center;

      h2 {
        margin:0 7px 0 0;
      }
    }
  }
  .ui-select-container {
    max-width:80%;
    bottom:-2px;
    .ui-select-match {
      max-width:100%;

      .ui-select-match-text,
      .ui-select-placeholder {
        font-size:31px;
        span {
          max-width:100%;
          min-height:31px;
          overflow-x:hidden;
          text-overflow:ellipsis;

        }
      }
    }
    .ui-select-choices {
      max-height:60vh;
      .ui-select-choices-row-inner {
        padding-top:6px;
        padding-bottom:6px;
        i {
          margin-bottom:2px;
        }
      }
    }
    .btn {
      border-bottom-color:transparent;
      &:hover {
        border-bottom-color:#fff;
      }
    }
  }
}

.results-sample {

  .results-sample-item {
    background:#fff;
    margin-bottom:30px;
  }
  .row {
    margin-left:0;
    margin-right:0;
  }

  pre {
    padding:15px;
  }
  .c3 svg {
    max-width:100%;
  }

  p.border-left {
    border-left:3px solid $gray-lighter;
    margin-left:15px;
    padding-left:15px;
  }
}


.results-details {
  display:flex;
  margin-left:-15px;
  margin-bottom:15px;
  .label-action {
    align-self:flex-start;
  }
  .label-item {
    flex-grow:1;
    padding:0 15px;
    margin-right:15px;
    border-right:1px solid $gray-lighter;
    small {
      color:$gray;
      max-width: 250px;
      display: inline-block;
    }
    .conf-bg {
      padding:5px;
      border-radius:$border-radius-base;
      color:#fff;
    }
    &:last-of-type {
      border-right:0;
      margin-right:0;
      padding-right:0;
    }
    &.label-item-lg {
      max-width:none;
    }
  }
  .target-by {
    label {
      display:block;
    }
    .btn-grid {
      display:flex;
      flex-wrap:wrap;
      margin:-5px;
      .btn {
        flex-grow: 1;
        min-width:45px;
        margin:5px;
      }
    }
  }
  .ui-select-container {
    margin-top:-15px
  }
  @media(max-width:920px) {
    flex-wrap:wrap;
    .label-item {
      flex-basis: calc(50% - 15px);
      flex-grow: initial;
      max-width: initial;
      margin-bottom:15px;
      &:nth-of-type(even) {
        border-right:none;
      }
    }
  }
  @media(max-width:640px) {
    .label-item {
      flex-basis: calc(100% - 30px);
      border-right:none;
    }
  }
}

.results-text-summary {
  text-align:center;
  padding:15px calc(25px + 10vw) 30px calc(25px + 10vw);
  h2 {
    font-size:24px;
    line-height:38px;
  }
}

.summary-title {
  display:flex;
  align-items:center;
  .summary-title-image {
    text-align:right;
    width:25%;
    margin-right:60px;
    opacity:.15;
  }
}

.workflow {
  padding-top:15px;
  .workflow-step {
    display:flex;
    strong.title {
      letter-spacing:2px;
      text-transform:uppercase;
      display:inline-block;
    }
    .workflow-label {
      position:relative;
      width:25%;
      flex-shrink:0;
      text-align:right;
      padding-right:30px;
      margin-right:60px;
      border-right:4px solid $gray-light;
      strong.title {
        color:$brand-primary;
      }
      h2 {
        margin-top:10px;
        font-size:24px;
      }
      &:before {
        content:'';
        display:block;
        width:20px;
        height:20px;
        border:4px solid $gray-light;
        border-radius:100px;
        background:$body-bg;
        position:absolute;
        right:-12px;
        z-index:2;
        transition-delay:0s;
      }
      &:after {
        content: '';
        display:block;
        height:0%;
        border-right:4px solid $brand-info;
        position:absolute;
        right:-4px;
        top:0;
        z-index:1;
        transition:height .75s ease-in-out;
      }
    }
    .workflow-content {
      width:75%;
      margin-bottom:120px;
      opacity:0;
      h2 {
        margin-top:0;
      }
      strong.title {
        color:#fff;
        margin-bottom:20px;
      }
      &.light-card {
        strong.title {
          color:$gray-darker;
        }
      }
    }
    &.hide-line {
      .workflow-label {
        border-right-color:transparent;
        &:after {
          display:none;
        }
      }
    }
    &.hide-dot {
      .workflow-label {
        &:before {
          display:none;
        }
      }
    }
    &.scrolled-to {
      .workflow-content {
        animation: fadeInUp .25s;
        animation-fill-mode: both;
        opacity:1;
      }
      .workflow-label {
        &:before {
          border-color:$brand-info;
          background:$brand-info;
        }
        &:after {
          height:100%;
        }
      }
      + .workflow-step {
        .workflow-label {
          &:before {
            transition:border 0s ease-in-out;
            transition-delay:.75s;
            border-color:$brand-info;
          }
        }
      }
    }
  }
}