.design-loader-wrapper {
  &.ng-hide-add {
    animation: fadeOut .5s;
    animation-delay: .25s; // Gives the last checkbox time to check
    position: absolute;
    width:100%;
    height:calc(100% - 100px);
    left:0;
    right:0;
    overflow:hidden;
    z-index:-1;
  }
}

.design-loader {
  position:relative;
  z-index:1;
  ul {
    list-style-type:none;
    font-weight:300;
    margin:0;
    padding:0;
    li {
      display:flex;
      align-items:center;
      font-size:24px;
      padding:10px 0;
      color:#859093;
      transition:all .25s ease-in-out;

      i {
        margin-right:15px;
        color: #001F26;
        font-size:29px;

        text-shadow:
         -1px -1px 0 #48565A,  
          1px -1px 0 #48565A,
          -1px 1px 0 #48565A,
           1px 1px 0 #48565A;
      }
      &.active {
        color:#fff;
        i {
          animation: flipInX .75s;
          color:$brand-primary;
          text-shadow:none;
        }
      }
    }
  }
}

#particles-js {
  position:absolute;
  z-index:0;
  left:0;
  right:0;
  top:0;
  bottom:0;
  height:100vh;
  width:100vw;
  overflow:hidden;
}