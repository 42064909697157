.wrapper {
  display:flex;
  min-height:100vh;
  flex-direction:column;
  .main {
    flex:1;
    padding-top:calc( #{$navbar-height} + #{$navbar-margin-bottom} + 2px);
    z-index:0;
    
    &.ng-enter {
      animation:fadeIn .25s;
    }

    &.ng-leave {
      //animation:fadeOut 1s;
    }
  }
}

.page-centered {
  display:flex;
  flex-direction:column;
  min-height:70vh;
  justify-content:center;
  > .container-fluid {
    width:100%;
  }
  .analyze & {
    > .container-fluid {
      min-height:940px;
    }
  }
}

.content-centered {
  display:flex;
  justify-content:center;
  align-items:center;
}

.detail-pane {
  position:sticky;
  top:71px;
  background:#fff;
  z-index:$zindex-navbar - 1;
  padding:15px 0 0 0;
  height:$detail-pane-height;

  .content-centered {
    height:calc(#{$detail-pane-height} - 100px);
  }

  .tab-content {
    overflow-y:auto;
    height:calc(#{$detail-pane-height} - 100px);
  }

}

footer {
  background:$body-bg;
  border-top:1px solid $navbar-default-border;
  padding:10px 15px;
  margin-top:30px;
  position:relative;
  z-index:$zindex-navbar - 1;
  display:flex;
  align-items:center;
  color:rgba(255,255,255,.5);

  > ul { 
    margin-bottom:0; 
    &:last-child {
      margin-left:auto;
    }
    > li {
      letter-spacing:1px;
      text-transform:uppercase;
      > a {
        font-weight:bold;
        color:$brand-primary;

        &.muted {
          color:#fff;
          small {
            color:rgba(255,255,255,.5);
          }
          &:hover small {
            color:#fff;
          }
        }
        &:hover, &:focus {
          text-decoration:none;
        }
      }

      ul.dropdown-menu {
        text-transform:none;
        letter-spacing:0;
      }
    }
  }
  @media(max-width:740px) {
    flex-direction:row;
    flex-wrap:wrap;
    justify-content:center;
    text-align:center;
    > ul:last-child {
      margin-left:initial;
    }
  }

  .alert {
    margin-top:-10px;
    margin-bottom:11px;
    border-radius:0;
    padding:10px;
  }
}

.experiments {
  margin-top:-25px;
  margin-bottom:20px;
  &.ng-enter,
  &.ng-leave-active {
    transform:rotateX(90deg);
  }
  &.ng-enter-active,
  &.ng-leave {
    transform:rotateX(0deg);
  }
}

.flash-messages {
  max-width: 1400px;
  padding: 0 15px;
  align-self: center;
  position: fixed;
  bottom: 115px;
  z-index:$zindex-modal + 1;
  font-weight:bold;
}

.label-item {
  span.term {
    color:#fff;
    font-weight:bold;
    display: block;
    max-width: 100%;
    margin-bottom: 5px;
  }
  strong.info {
    display:block;
    font-size:20px;
    font-weight:400;
    color:#fff;
    &.info-lg {
      font-size:32px;
    }
    .label {
      font-size:10px;
      position:relative;
      top:-2px;
    }
  }
  a.info-link {
    float:right;
    letter-spacing:2px;
    text-transform:uppercase;
    font-size:11px;
  }
  form & {
    strong.info {
      line-height:$input-height-large;
    }
    small {
      display:block;
      margin-top:-15px;
    }
  }
  hr.spacer {
    margin-top:23px;
    margin-bottom:54px;
  }
  &.label-has-description {
    form & {
      strong.info {
        margin-top:-10px;
      }
    }
  }
}

.update-loader {
  position: absolute;
  left: 50%;
  margin-left: -110px;
  top: 175px;
  z-index: 1;
}
.update-loading {
  opacity:.25;
}

.bar-chart {
  width:100%;
  margin:30px 0;
  dt, dd { margin-bottom:10px;}
  dd {
    display: flex;
    align-items: center;
    .bar {
      height:25px;
      margin-right:10px;
      background-color:$gray;
      display:inline-block;
    }
    &.active {
      color:$brand-primary;
      .bar { background-color:$brand-primary}
    }
  }
}

.modal-body.gko-bg {
  background:url("/images/kit-bg.svg") 0 -95px no-repeat;
}

.modal-review {
  .well {
    h3:first-child {
      margin-top:0;
    }
    hr {
      border-color:#424242;
    }
  }
  .hr-divider {
    margin-top:30px;
  }
  .coupon-block {
    display:flex;
    align-items: center;
    justify-content: center;
    margin: 30px 0;
    .price {
      white-space:nowrap;
      margin-right:15px;
      h2 {
        margin:0;
      }
    }
    .coupon {
      border: 2px dashed $gray;
      display: flex;
      padding-left: 15px;
      border-radius: $border-radius-base;
      input {
        border:none;
      }
      &:hover {
        border-color:#fff;
      }
    }
    p {
      margin:0 0 0 15px;
    }
  }
}

.intro-text,
.about .main {
  img {
    height:20vh;
    max-height:220px;
    min-height:130px;

  }
}

.main-form {
  max-width:1040px;
  margin-right:auto;
  margin-left:auto;

  form.ng-hide-remove {
    animation: fadeIn .3s;
  }
}

.filter-toggle {
  margin-top: -40px;
  text-align: right;
  position: relative;
  margin-bottom: 20px;
}

.clear-input-wrapper {
  position:relative;

  .btn-clear-input {
    position:absolute;
    border-radius:100em;
    box-shadow:none;
    text-align:center;
    padding: 0px 2px 2px 4px;
    font-size: 1.5em;
    line-height: 1em;
    color:rgba(#000,.75);
    top: 5px;
    right: 0;
    &:hover {
      background-color:rgba(#000, .1);
    }
  }
}

.help-icon {
  display:inline-block;
  position:relative;
  top:-2px;
  margin-bottom:-2px;
  color:$gray;
  &:hover {
    color:#fff;
  }
}

.popular-genomes {
  margin-top:10px;
  label, .btn {
    color:$gray-light;
    font-size:10px;
    margin-right:5px;
  }
  .btn {
    border-radius:20px;
    &:hover {
      color:#fff;
      border-color:$gray;
      background:transparent;
    }
  }
}

.form-inline-flex {
  display:flex;
  flex-wrap:wrap;
  padding:0 15px;
  .form-item {
    padding-right:30px;
    max-width:550px;
    flex-basis:0;

    &:first-child {
      flex-grow:2;
    }
    &:nth-child(2) {
      flex-grow:2;
    }
    &:nth-child(3) {
      flex-grow:1;
    }
  }
  button {
    margin-top:24px;
    align-self:flex-start;
  }  
}

.recent-dropdown {
  li {
    a {
      padding-top:10px;
      padding-bottom:10px;
    }
    span.name {
      display:block;
      max-width:320px;
      overflow:hidden;
      white-space:nowrap;
      text-overflow:ellipsis;
      margin-bottom:3px;
    }
    span.meta {
      font-size:12px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .status {
        display: flex;
        align-items: center;
      }
    }
  }
}

.toolbar {
  display:flex;
  align-items: center;

  .toolbar-item {
    flex-basis:33.33%;
    flex-grow:1;
    text-align:center;
    margin-right:15px;
    .share-dropdown {
      position: relative;
      display: inline-block;
      vertical-align: middle;
    }
    .btn {
      &.btn-link {
        padding-left: 0;
      }
    }
    h2 {
      margin:0
    };    
    &.toolbar-item-long {
      flex-basis:75%;
      @media(max-width:675px) {
        flex-basis:50%;
      }
    }
    &:first-child {
      text-align:left;
    }
    &:last-child {
      text-align:right;
      margin-right:0;
    }
    @media(max-width:985px) {
      flex-basis:initial;
    }
    @media(max-width:675px) {
      .btn-group {
        .btn {
          font-size:12px;
          padding:$padding-base-vertical $padding-base-horizontal;
        }
      }
    }
  }
  .navbar & {
    height:$navbar-height;
  }
}

.table {
  tr {
    transition:transform .2s linear;
    td {
      vertical-align:middle;
      height:47px;

      &.mono {
        .mismatch_base {
          border:1px solid rgba($gray, .4);
          border-radius:2px;
          transition:background-color .1s linear;
          padding-left: 2px;
          margin-left: -2px;
        }
      }

      &.all-caps {
        color:#fff;
        font-size:12px;
      }
    }
    .btn-hover-visible {
      opacity:0;
    }

    &:hover {
      td.mono .mismatch_base {
        border-color:rgba($brand-info, .4);
        background-color:rgba($brand-info, .3);
      }
      .btn-hover-visible {
        opacity:1;
      }
    }
    &.divider {
      background:transparent!important;
      td {
        border:none;
        background:transparent!important;
      }
    }
    &.ng-enter {
      transform:rotateX(90deg);
    }
    &.ng-enter-active {
      transform:rotateX(0deg);
    }
    &.ng-leave {
      transform:rotateX(0deg);
    }
    &.ng-leave-active {
      transform:rotateX(90deg);
    }
    //&.ng-enter-stagger {
    //  transition-delay: .05s;
    //  transition-duration: 0s;
    //}
  }
}

.table-recommended,
.table-additional,
.table-offtargets {
  table-layout:fixed;
  tbody > tr > td,
  thead > tr > th {
    width:14%;

    &.rank {
      width:7%;
    }
    &.guide {
      width:18%;
    }
    &.name {
      width:44%;
    }
    &.result {
      width:4%;
    }
    &.actions {
      width:11%;
      overflow:visible;
    }
  }
  tbody > tr > td {
    overflow: hidden;
    text-overflow: ellipsis;
  }

}

.table-samples {
  table-layout:fixed;
  tbody > tr > td,
  thead > tr > th {
    overflow: hidden;
    text-overflow: ellipsis;

    &.name {
      width:25%;
      > span {
        display:flex;
        align-items:center;
      }
      i {
        margin-right:10px;
      }
      button {
        margin-left:auto;
      }
    }
    &.ice,
    &.ice-d,
    &.rsq {
      width:8.3%;
      text-align:center;
      .label {
        font-size:14px;
      }
    }
    &.guide {
      width:22%;
    }
    &.pam {
      width:15%;
    }
    &.actions {
      width:13%;
      overflow:visible;
    }
  }
  tbody > tr:hover {
    td.name strong {
      text-decoration:underline;
    }
  }
}
.table-recommended {
  > tbody > tr {
    > td.guide {
        a {
          color:$brand-primary;
        }
    }
    &.active {
      > td {
        background:lighten($brand-primary, 42%)!important;
        border-color:lighten($brand-primary, 34%)!important;
      }
      &:hover,
      &.hover {
        > td {
          background:lighten($brand-primary, 38%)!important;
        }
      }
    }

  }
}
.table-sticky-header {
  table-layout:fixed;
  position:sticky;
  background:#fff;
  z-index:$zindex-navbar - 2;
  top:calc(#{$detail-pane-height} + 69px);
  margin-bottom:0;
  &.scrolled-to {
    @include box-shadow(0px -8px 8px -8px rgba(0,0,0,.375), 0px 8px 8px -8px rgba(0,0,0,.375));
  }
}


.mono {
  font-family:$font-family-monospace;
  letter-spacing:.15em;
  text-transform:uppercase;
  font-weight:bold;
  h2 & {
    font-size:1.2em;
  }
}

.all-caps {
  text-transform:uppercase;
  letter-spacing:1px;
  font-weight:bold;
}

.hr-divider {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  text-transform: uppercase;

  &:before {
    position: absolute;
    top: 50%;
    display: block;
    content: "";
    width: 100%;
    height: 1px;
    background-color: $hr-border;
  }

  .hr-divider-inner {
    position: relative;
    z-index: 2;
    display: inline-block;
    padding-left: 1em;
    padding-right: 1em;
    vertical-align: middle;
    background-color: $body-bg;
    margin-top: 0;
    margin-bottom: 0;
    font-size: 100%;
    color: #fff;
    font-weight:bold;
    font-family:$font-family-sans-serif;
  }
}

.alert-promo {
  border-radius:0;
  padding:10px;
  position:relative;
  top:$navbar-height;
  width:100%;
  z-index:$zindex-navbar - 1;
  text-transform:uppercase;
  letter-spacing:1px;

  &.ng-enter {
    animation:slideInDown .25s;
  }
  &.ng-leave {
    animation:slideOutUp .25s;
  }
}

.coupon-code {
  font-size:larger;
  padding:3px 6px;
  background-color:rgba(255,255,255,.25);
}

.alert-container {
  width:100%;
  .btn {
    border-color:#fff;
  }
}

form .hint {
  display:block;
  font-size:14px;
  margin-top:10px;

  a {
    color:$text-muted;
    text-decoration:underline;
    &:hover {
      color:#fff;
    }
  }
}

.well-tabs {
  position:relative;

  .nav-tabs {
    border-color:$well-border;
    max-width: 540px;
    margin: 0 auto;

    //display: flex;
    //justify-content: flex-end;
    //margin-right:-2px;

    //@media(min-width:1150px) {
    //  width:438px;
    //  transform: rotate(90deg);
    //  transform-origin: right;
    //  position:absolute;
    //  top: 418px;
    //  right: -19px;
    //  justify-content: flex-start;
    //}

    > li {
      > a {
        color:$gray-light;
        border-color: $well-border;
        &:hover {
          color:#fff;
          border-color: $nav-tabs-link-hover-border-color $nav-tabs-link-hover-border-color $nav-tabs-border-color;
        }
      }

      &.active > a {
        &,
        &:hover,
        &:focus {
          color:#fff;
          background-color: $well-bg;
          border-color: $well-border;
          border-bottom: 3px solid #011c23;
          margin-bottom: -3px;
        }
      }
    }
  }
}

.help-link {
  color:#fff;
  display:flex;
  align-items:center;
  border-radius:$border-radius-base;
  padding:15px;

  span {
    margin-left:15px;
    strong {
      display:block;
      font-size:18px;
      font-weight:normal;
    }
    small {
      color:$gray-light;
      font-size:14px;
    }
  }
  &:hover, &:active, &:focus {
    text-decoration:none;
    color:#fff;
    background:rgba(0,0,0,.15);
  }
  &.disabled{ 
    cursor:not-allowed; 
    &:hover {
      background:transparent;
    }
  }
}

.floating-nav {
  position:absolute;
  left:0;
  top:81px;
  background:rgba(0,0,0,.25);
  border-radius:$border-radius-base;
  opacity:.5;

  &:hover {
    opacity:1;
  }

  .dropdown-toggle {
    color:#fff;
  }
}

.border-l { border-left:1px solid $hr-border }
.border-r { border-right:1px solid $hr-border }
.border-t { border-top:1px solid $hr-border }
.border-b { border-bottom:1px solid $hr-border }

.rotate-90  { transform: rotate(90deg);  }
.rotate-180 { transform: rotate(180deg); }
.rotate-270 { transform: rotate(270deg); }

@-webkit-keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}

.spin {
  animation: spin 2s infinite linear;
  animation-direction: reverse;
}

@mixin palette($colors, $stops, $class) {
  @for $c from 1 through length($colors) - 1 {
    $s1: nth($stops, $c);
    $s2: nth($stops, $c + 1);
    $c1: nth($colors, $c);
    $c2: nth($colors, $c + 1);
    @for $i from $s1 through $s2 {
      $mixed: mix($c2, $c1, 100 * ($i - $s1) / ($s2 - $s1));

      .#{$class}-#{$i}.conf-bg {
        background-color: $mixed!important;
      }

      .#{$class}-#{$i}.conf-color {
        color: $mixed!important;
      }

    }
  }
}

// Palette A
$palette-colors: $brand-danger, $brand-warning, $brand-success;
$palette-stops: 0, 50, 100;

@include palette($palette-colors, $palette-stops, conf);

//Toggle checkbox
.checkbox-toggle {
  display: none;
  
  // add default box-sizing for this scope
  &,
  &:after,
  &:before,
  & *,
  & *:after,
  & *:before,
  & + .toggler {
    box-sizing: border-box;
    &::selection {
      background: none;
    }
  }
  
  + .toggler {
    background: $gray-darker;
    border-radius: 15px;
    margin-bottom:-2px;
    padding: 2px;
    transition: all .4s ease;    
    outline: 0;
    display: inline-block;
    width: 30px;
    height: 15px;
    position: relative;
    cursor: pointer;
    user-select: none;
    &:after,
    &:before {
      position: relative;
      display: block;
      content: "";
      width: 11px;
      height: 100%;
    }
    
    &:after {
      left: 0;
      border-radius: 50%;
      background: #fff;
      transition: all .2s ease;
    }
    
    &:before {
      display: none;
    }
  }
  
  &:checked + .toggler {
    background: $brand-primary;
    &:after {
      left: calc(50% + 2px);
    }
  }
}