$cds: #cdb9ff;
$exon: #8254fb;
$highlight:$brand-warning;
$guide: #a4abb7;
$guide-picked: $brand-primary;
$gray-tick: #e2e2e2;

.viz-wrapper {
  position:sticky;
  top:71px;
  background:#fff;
  z-index:$zindex-navbar - 1;
}

.viz-design {
  max-height:33vh;
  transition: all .25s ease-in-out;

  line {
    shape-rendering:crispEdges;
  }

  .guide {
    stroke: $guide;
    &.picked {
      stroke: $guide-picked;
    }
    &:hover, &.active, &.picked.active {
      stroke: $brand-info;
    }    
  }

  .guide-cutsite {
    pointer-events:none;
    stroke: rgba(0,0,0,.25);
    stroke-width:1;
    stroke-dasharray:1,1;
  }

  .axis {
    font-size:12px;;

    line, path {
      fill: none;
      stroke: none;
      shape-rendering: crispEdges;
    }
    .tick {
      line {
        stroke: $gray-tick;
      }
      text {
        padding-top:10px;
        fill: $gray-dark;
      }
    }
  }

  .x-indicator {
    pointer-events:none;
    line {
      stroke: rgba(0,0,0,.25);
      stroke-width:1;
    }
    text {
      fill: rgba(0,0,0,.5);
    }
  }

  .exon {
    fill: $exon;
    &:hover {
      opacity:.75;
    }
  }

  .cds {
    fill: $cds;
    &:hover {
      opacity:.75;
    }
  }
  .divider {
    stroke-width:2;
    stroke: $highlight;
  }
  .pam-label {
    font-size:10px;
    text-transform:uppercase;
    letter-spacing:1px;
    fill: $gray;
  }
  .gene-label {
    font-weight:bold;
    fill: $gray;
  }

  .chart-label {
    text-transform:uppercase;
    letter-spacing:1px;
    font-weight:bold;
    font-size:10px;
    &.exon-label {
      fill:$exon;
    }
    &.cds-label {
      fill:$cds;
    }
  }
  .line-label {
    stroke-width:1;
    &.exon-line {
      stroke:$exon;
    }
    &.cds-line {
      stroke:$cds;
    }
  }

  .strand-direction {
    stroke-width:2;
    stroke:$gray-light;
  }
  #strandmarker {
    stroke:$gray-light;
    fill:#fff;
  }
  #guidemarker {
    fill:darken($guide, 20%);
  }
}

.viz-tooltip {
  transition:opacity .25s ease-in-out;
  pointer-events:none;
  background: $gray;
  border-radius:$border-radius-base;
  color: #fff;
  font-size: 12px;
  padding: 8px;
  margin:5px 0 0 5px;
  position: absolute;
  text-align: left;
  z-index: 100000;
  opacity: 0;
  > div > strong {
    color:#fff!important;
  }
}