
/*
 * Styling for the table row shown in empty tables
 */
/* The row is always added as the first row in a table
   Hide it by default */
/* Only show it if it is also the last row of the table. */
th.tablesort-sortable {
  user-select: none;
  cursor: pointer;
  &:hover {
    background-color:#f8f8f8;
  }
}
table {
  .tablesort-sortable {
    position:relative;
    padding-right:15px!important;
    &:after {
      content: "";
      margin-top: 7px;
      visibility: hidden;
      position:absolute;
      right:5px;
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      border-top: none;
      border-bottom: 4px solid #000;
    }
    &:hover {
      &:after {
        visibility: visible;
      }
    }
  }
  .tablesort-desc {
    &:after {
      border-top: 4px solid #000;
      border-bottom: none;
      visibility: visible;
    }
    //background-color: #f1f1f1;
  }
  .tablesort-asc {
    //background-color: #f1f1f1;
    &:after {
      visibility: visible;
    }
  }
}
.showIfLast {
  display: none;
  //&:last-child {
  //  display: table-row;
  //}
  //td {
  //  text-align: center;
  //  &:after {
  //    content: "No data";
  //  }
  //}
}
