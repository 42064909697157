
hubspot-form .submitted-message {
  @extend .alert, .alert-success;
  text-align:center;
  font-size:14px;
}

.hs-form {
  fieldset {
    max-width:none!important;
  }
  .hs-input[type="text"], 
  .hs-input[type="email"],
  .hs-input[type="tel"],
  select.hs-input, 
  textarea.hs-input {
    @extend .form-control;
    @extend .input-lg;
    margin-bottom:27px;
    width:100%!important;

    &.has-error {
      border-color:$brand-danger;
      margin-bottom:0;
    }

  }

  select.hs-input {
    option {
      color:$gray-darker;
    }
  }

  textarea {
    min-height:15vh;
  }

  .hs_submit {
    margin:29px 0 0 0;
  }

  ul.inputs-list.multi-container {
    margin: 15px 0;
    padding: 0;
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;
    li {
      flex-basis: 50%;
      label {
        display: flex;
        padding: 0 10px 10px 0;

        span {
          text-transform:none;
          letter-spacing:0;
          font-weight:normal;
          font-size:14px;
        }

        .hs-input[type="checkbox"] {
          flex-shrink: 0;
          margin: 5px 10px 2px 0;
        }
      }
    }
  }

  ul.help-block {
    list-style-type:none;
    padding:0;
    margin-bottom:0;
    label {
      letter-spacing:0;
      font-weight:normal;
      text-transform:none;
    }
    &.has-error {
      label {
        color:$brand-danger;
      }
    }
  }
  .hubspot-inline & {
    display:flex;
    .hs_email {
      flex-grow:1;
    }
    .hs_submit {
      margin-left:15px;
    }
  }
}