.light-card-tabs .nav-tabs {
  margin-bottom:-2px;
  
  li {
    a {
      border:none!important;
      border-radius:0;
      font-size:14px;
      color:#fff;
      background:rgba(0,0,0,.75);
      padding: 15px 21px;
    }
    &.active a {
      border-radius:$border-radius-large $border-radius-large 0 0;
      color:$gray-darker;
      background:#fff;
    }
    &:not(.active):hover a {
      color:#fff;
      background:rgba(0,0,0,.25);;
    }
    &:first-child:not(.active) a {
      border-radius:$border-radius-large 0 0 0;
    }
    &:last-child:not(.active) a {
      border-radius:0 $border-radius-large 0 0;
    }
  }
}

.light-card {
  position:relative;
  background:#fff;
  padding:30px;
  border-radius:$border-radius-small;

  &.card-lg {
    padding:75px;
  }

  > h1 {
    margin-top:0;
  }

  .hr-divider:first-child {
    margin-top:0;
  }

  .logo-loader {
    transform:scale(.5);
    > div > div {
      animation-name: line-scale-pulse-out-gray;
      //opacity:.5;
    }
  }

  .chart .logo-loader {
    position:absolute;
    top:30px;
  }

  .table-striped > tbody > tr:nth-of-type(odd) {
    background-color:#f8f8f8;
  }
  .table {
    >tbody+tbody {
      border-top-color:$gray-lighter;
    }
    th, td {
      color:$gray-dark;
      border-color:$gray-lighter;
    }
    .text-muted {
      th, td, strong {
        color:$gray-light;
      }
    }
    &.table-hover {
      tr {
        &:hover, 
        &.hover,
        &.active {
          td {
            background-color:darken($gray-lighter, 1%);
          }
        }
        &.active:hover,
        &.active.hover {
          td {
            background-color:darken($gray-lighter, 2%);
          }
        }
      }
    }
  }

  .table-responsive {
    @media screen and (max-width: 767px) {
      border-color:$gray-lighter;
    }
  }

  .help-icon:hover {
    color:#000;
  }
  select.form-control.input-lg {
    background-image:url('data:image/svg+xml;utf8,<svg width="14" height="14" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg"><path fill="#000000" d="M1408 704q0 26-19 45l-448 448q-19 19-45 19t-45-19l-448-448q-19-19-19-45t19-45 45-19h896q26 0 45 19t19 45z"/></svg>');
  }
  .label-item span.term,
  .label-item strong.info,
  .table-thead,
  strong,
  p,
  h1,h2,h3,h4,h5,h6,
  ul li, ol li, dl dd, dl dt {
    color:$gray-dark;
  }
  .text-primary {
    color:$brand-primary!important;
  }
  .text-warning {
    color:$brand-warning!important;
  }
  .text-danger {
    color:$brand-danger!important;
  }
  .well {
    color:$gray-dark;
    background-color:$gray-lighter;
    border:none;
  }

  hr:not(.spacer),
  .border-l,
  .border-r,
  .border-t,
  .border-b {
    border-color:#d8d8d8;
  }

  .form-control {
    color:$gray-dark;
    background:#fff;
    border-color:#d8d8d8;
    &:focus {
      border-color:$component-active-bg;
    }
  }
  a:not(.btn) {
    color:$gray-dark;
  }
  .btn {
    &.btn-default {
      color:$gray;
      background-color:$gray-lighter;
      border-color:transparent;
    }
    &.btn-default-outline {
      color:#a4abb7;
      border-color:#a4abb7;
      &:focus:not(.active):not(:active):not(:hover) {
        color:#a4abb7;
      }
      &:hover,
      &.active {
        background-color:#a4abb7;
        color:#fff;
      }
    }
    &.btn-link {
      &.btn-link-muted {
        color:$gray;
      }
    }
  }
  .nav>li:not(.active) {
    > a:hover,
    > a:focus {
    background:$gray-lighter;
    }
  }
  .popover {
    background:#fff;  

    .arrow:after {
      border-bottom-color:#fff;
    }
    label {
      color:$gray-dark;
    }
  }
  .hr-divider {
    margin:15px 0;
    &:before {
      background-color: #d8d8d8;
    }
    .hr-divider-inner {
      background-color: #fff;
    }
    h5.hr-divider-inner {
      color:$gray-darker;
    }
  }
  &.ng-enter,
  &.ng-leave-active {
    transition:0.25s ease-in-out all;
    opacity:0;

    &.ng-enter-active,
    &.ng-leave {
      opacity:1;
    }
  }
}
