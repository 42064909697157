html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  position:relative;
}

a[ng-click],[ui-sref] {
  cursor:pointer;
}

::selection { background: $brand-info; }

.force-lower {
  text-transform:none!important;
}

.text-white {
  color:#fff!important;
}

.bg-white {
  background:#fff!important;
}

.highlight {
  background:rgba($brand-warning, .3);
  border:1px solid rgba($brand-warning, .4);
  border-radius:2px;
  transition:background-color .1s linear;
}

.container-fluid {
  max-width:1400px;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.btn:not(span) {
  font-weight:bold!important; //Shouldn't be necessary
  &:focus {
    outline:none!important;
  }
  &.btn-lg {
    font-size:14px;
  }
  &.btn-link {
    &:hover, &:focus, &:active {
      text-decoration:none;
    }
    &.btn-link-muted {
      color:#fff;
    }
  }
}

.progress {
  font-weight:bold;

  &.progress-tall {
    height:auto;
    .progress-bar {
      font-size:14px;
      padding: 15px 0;
    }
  }
}

.btn-group {
  &.btn-group-toggle {
    > .btn {
      i {
        opacity:0;
      }
      &.active {
        i {
          opacity:1;
          color:$brand-success;
        }
      }
    }
  }
}

&.btn-group-tabs {
  display:flex;
  margin-bottom:-2px;
  > .btn {
    @include border-bottom-radius(0);
    border:none;
    flex-grow:1;
    background:rgba(0,0,0,.75);
    &.active {
      background:#fff;
    }
    &:not(.active):hover {
      color:#fff;
      background:rgba(0,0,0,.25);;
    }
  }
}

.well {
  box-shadow:none;
  &.well-dark {
    background-color:$gray-darker;
  }

  .table {
    tr > td {
      border-color:$gray-dark;
    }
  }
}

.material-icons {
  vertical-align:middle;
  cursor:default;

  &.md-14 { font-size: 14px; }
  &.md-16 { font-size: 16px; }
  &.md-18 { font-size: 18px; }
  &.md-24 { font-size: 24px; }
  &.md-36 { font-size: 36px; }
  &.md-48 { font-size: 48px; }
}

.tooltip {
  .tooltip-inner {
    padding:10px;
    text-align:left;
    font-size:13px;
  }
}

.form-control {
  @include box-shadow(none);
  font-weight:400;

  &:focus {
    @include box-shadow(none!important);
    outline:none;
  }
  &.form-control-mono {
    font-family:$font-family-monospace;
    font-weight:bold;
    &::placeholder {
      font-family:$font-family-sans-serif;
      font-weight:400;
      letter-spacing:0;
      text-transform:none;
    }
  }
  &.input-lg,
  .form-group-lg & {
    font-weight:400;
  }
}

.help-block {
  transition:height 1s ease-in-out;
  min-height:19px;
  &.help-hidden {
    visibility:hidden;
  }
  &.help-visible {
    visibility:visible;
  }
  a {
    color:$brand-danger;
    font-weight:bold;
    text-decoration:underline;
  }
  p[ng-message] {
    display:inline;
  }
  &.help-block-lg {
    font-size:14px;
    p:before {
      content:'error_outline';
      font-family: "Material Icons";
      text-rendering: optimizeLegibility;
      font-feature-settings: 'liga' 1;
      font-style: normal;
      text-transform: none;
      line-height: 1;
      display:inline-block;
      width: 18px;
      height: 16px;
      vertical-align: middle;
    }
  }
}

.dropdown-menu {
  @include box-shadow(0 6px 12px rgba(0,0,0,.375));
  max-height: 95vh;
  overflow-y: auto;

  .dropdown-header {
    @extend .all-caps;
    border:none;
  }
}

.nav > li > a {
  font-weight:bold;
  font-size:12px;
}

label {
  color:#fff;
}

input {
  box-shadow:none;
}

.form-group-lg .form-control,
.input-lg {
  font-size:20px;
}

.form-control {
  border-width:0 0 1px 0;
  padding-left:0!important;
  padding-right:0!important;

  &:active, &:focus, &:hover {
    border-width:0 0 2px 0;
  }
}

.form-group-lg select.form-control,
select.form-control.input-lg {
  line-height:$input-height-large / 2;
  padding-top:0;
  padding-bottom:0;
  padding-right:32px!important;
  appearance:none;
  background-image:url('data:image/svg+xml;utf8,<svg width="14" height="14" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg"><path fill="#ffffff" d="M1408 704q0 26-19 45l-448 448q-19 19-45 19t-45-19l-448-448q-19-19-19-45t19-45 45-19h896q26 0 45 19t19 45z"/></svg>');
  background-position:calc(100% - 10px) 50%;
  background-repeat:no-repeat;
}



input[name=sequence] {
  text-transform:uppercase;
}

.dropdown-menu {
  font-size:16px;
}

hr.spacer {
  border-color:transparent;
}

p {
  font-weight:400;
  font-family: "open-sans", sans-serif;
  &.lead {
    font-family: $font-family-sans-serif;
    font-weight:400;
    margin-bottom:$line-height-computed * 1.5;
    &.lead-center {
      max-width:70%;
      margin:0 auto;
      text-align:center;
    }
  }
}

.lead {
  font-weight:400;
  line-height:1.6;
  p, ul, ol, {
    margin-bottom:$line-height-computed * 1.5;
  }
}

h1, h2, h3, h4, h5 {
  color:#fff;
}

.list-lg {
  font-size:1.35em;
  line-height:2.65em;
}
.jumbotron {
  h1 {
    font-weight:200;
    margin-bottom:5vh;
  }
  h2 {
    color:$brand-primary;
    margin-bottom:5vh;
  }
}

h5 {
  font-weight:bold;
  text-transform:uppercase;
  letter-spacing:2px;
}

.table {
  > thead {
    color:#fff;
    font-size:12px;
    text-transform:uppercase;
    letter-spacing:1px;

    > tr > th {
      border:none;
    }
  }
  > tbody {
    > tr > td {
      font-size:14px;
    }
  }
  &.table-vertical {
    td {
      vertical-align:middle;
    }
  }
  &.table-hover {
    > tbody > tr.hover {
      background-color: $table-bg-hover;
    }
  }
}

.modal {
  .modal-header {
    border-bottom-color:#131313;
    .modal-title {
      font-size:14px;
      text-transform:uppercase;
      letter-spacing:1px;
      font-weight:bold;
    }
  }
  .close {
    font-size:48px;
    &.pull-right {
      position:absolute;
      right:15px;
      top:15px;
    }
  }
}

.alert {
  a.alert-link {
    color:#fff;
    opacity:.9;
    text-decoration:underline;
  }
}


@mixin button-outline-variant($color, $activeTextColor: #fff) {
  color: $color;
  background-color: transparent;
  border-color: $color;

  &:hover,
  &:active,
  &.active {
    color: $activeTextColor;
    background-color: $color;
    box-shadow: none;
  }

  &:focus:not(:active):not(.active):not(:hover),
  &.focus:not(:active):not(.active):not(:hover) {
    color: $color;
  }

  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    &,
    &:hover,
    &:focus,
    &.focus,
    &:active,
    &.active {
      border-color: $color;
    }
  }

  .badge {
    color: $activeTextColor;
    background-color: $color;
  }
}

.btn-default-outline {
  @include button-outline-variant($gray-lighter, $gray-darker);
}
.btn-primary-outline {
  @include button-outline-variant($btn-primary-border);
}
.btn-success-outline {
  @include button-outline-variant($btn-success-border);
}
.btn-info-outline {
  @include button-outline-variant($btn-info-border);
}
.btn-warning-outline {
  @include button-outline-variant($btn-warning-border);
}
.btn-danger-outline {
  @include button-outline-variant($btn-danger-border);
}


