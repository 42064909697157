// Logo Loader

@keyframes line-scale-pulse-out {
  0% {
    background:$brand-primary;
    transform: scaley(1.0);
  }
  50% {
    background:$brand-info;
    transform: scaley(0.4);
  }
  90% {
    background:$brand-primary;
    transform: scaley(1.0);
  }
  100% {
    background:$brand-primary;
    transform: scaley(1.0);
  }
}


@keyframes line-scale-pulse-out-gray {
  0% {
    background:$gray-lighter;
    transform: scaley(1.0);
  }
  50% {
    background:$gray-light;
    transform: scaley(0.4);
  }
  90% {
    background:$gray-lighter;
    transform: scaley(1.0);
  }
  100% {
    background:$gray-lighter;
    transform: scaley(1.0);
  }
}

.logo-loader {
  width:100%;
  text-align:center;
  display:inline-block;
  padding:15px;

  > div {
    display:inline-block;
    transform: rotate(225deg);
    height: 70px;    
    width: 140px;

    > div {
      background:#fff;
      width: 8px;
      margin: 2px;
      animation-fill-mode: both;
      transform-origin: bottom;
      display: inline-block;
      animation: line-scale-pulse-out 0.9s -0.6s infinite cubic-bezier(.85,.25,.37,.85);

      &:nth-child(1) { 
        animation-delay: -0.1s;
        height:100% 
      }
      &:nth-child(2) { 
        animation-delay: -0.2s;
        height:75% 
      }
      &:nth-child(3) { 
        animation-delay: -0.3s;
        height:50% 
      }
      &:nth-child(4) {
        animation-delay: -0.4s;
        height:25% 
      }
    }
  }
}

.circle-loader {
  border: solid rgba(#fff, .2);
  border-top-color: #fff;
  border-radius: 50%;
  animation: spin 1s infinite linear;
  content: "";
  display:inline-block;
  vertical-align:middle;
  width: 21px;
  height: 21px;
  border-width: 3px;
} 

.btn {
  &.btn-link {
    .circle-loader {
      border: solid rgba($link-color, .2);
      border-top-color: $link-color;
    }
  }
  .circle-loader {
    &.circle-loader-icon-overlay {
      position:absolute;
      width:29px;
      height:29px;
      margin-top:-6px;
      margin-left:-6px;
    }
  }
  &.btn-lg {
    .circle-loader {
      &.circle-loader-icon-overlay {
        position:absolute;
        width:30px;
        height:30px;
        margin-top:-2px;
        margin-left:-3px;
      }
    }
  }
}

.btn-loading {
  position:relative;
  &:after {
    z-index: 10;
    border: solid rgba(#fff, .2);
    border-top-color: #fff;
    border-radius: 50%;
    animation: spin 1s infinite linear;
    content: "";
    position: absolute;
    right: 30px;
    top: 50%;
    width: 20px;
    height: 20px;
    margin-top: -10px;
    border-width: 3px;
  }
}