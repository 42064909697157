.navbar {

  .nav > li {
    > a {
      font-weight:bold;
      font-size:12px;
      i {
        margin-top:-2px;
      }
    }
  }
  &.navbar-flex {
    display:flex;
    .navbar-item {
      display:flex;
      align-items:center;

      &:first-child {
        margin-right:auto;
      }
      &.navbar-brand {
        margin-right:auto;
      }
    }
    .navbar-nav {
      margin:0;
    }
  }

}

.navbar-panel {

  transition:all 0.25s ease-in-out;
  transform:translate3d(0,0,0);
  position:fixed;
  z-index:$zindex-modal-background - 5;
  height:100vh;
  display:flex;

  &.ng-hide {
    transform:translate3d(-395px,0,0);

    .navbar-panel-overlay {
      opacity:0;
    }
  }

  .navbar-panel-overlay {
    transition:opacity 0.25s ease-in-out;
    width:100vw;
    height:100vh;
    background:#000;
    opacity:.5;
  }

  .navbar-panel-menu {
    position:relative;
    background:$gray-darker;
    max-width:440px;
    width:100vw;
    height:100vh;
    padding:15px 0;

    .close {
      font-weight:lighter;
      position:absolute;
      z-index:$zindex-modal-background - 4;
      right:15px;
      font-size:34px;
    }
    .nav {
      > li > a {
        color:#fff;
      }
      &.panel-nav {
        > li {
          &.nav-label {
            color:#fff;
            font-family:$font-family-monospace;
            text-transform:uppercase;
            letter-spacing:2px;
            padding: 10px 15px;
          }
          > a {
            text-transform:none;
            letter-spacing:0;
            font-weight:normal;
            display:flex;

            img {
              margin-right:10px;
            }
          
            h3 { 
              font-size:18px;
              margin:0;
            }

            p {
              color:$gray-light;
              margin:0;
            }
          }
        }
      }
    }
  }
}