.typeahead {
  position:relative;
  &.loading:after {
    z-index: 10;
    border: solid rgba(#fff, .2);
    border-top-color: #fff;
    border-radius: 50%;
    animation: spin 1s infinite linear;
    content: "";
    position: absolute;
    right: 0px;
    top: 50%;
    width: 30px;
    height: 30px;
    margin-top: -15px;
    border-width: 4px;
  }
  .typeahead-title {
    display:block;
  }
  .typeahead-description {
    font-size:10px;
  }
  .uib-typeahead-match {
    strong {
      border:1px solid rgba($brand-warning,.5);
      border-radius:$border-radius-small;
    }
    &.active strong {
      border-color:rgba(#fff,.5)
    }
  }
  ul.dropdown-menu {
    width:100%;
    max-height:40vh;
    overflow-y:auto;
    margin-top: 0;
    padding-top: 0;
    border-width:0;
    @include border-top-radius(0);
    li {
      overflow:hidden;
    }
  }
  input.typeahead-has-description {
    transition:padding .15s linear;

    &.ng-valid {
      padding-top:0;
      position:relative;
      bottom:0;
      z-index:2;
      + ul + div.typeahead-input-description {
        opacity:1;
      }
    }
  }

  .typeahead-input-description {
    opacity:0;
    transition:opacity .15s linear;
    color:$gray-light;
    position:absolute;
    z-index:1;
    bottom:9px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
  }
}